<template>
  <div class="list row">
    <div class="col-md-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Vorname" v-model="searchFields.forename" @keyup.enter="search"/>
        <input type="text" class="form-control" placeholder="Nachname" v-model="searchFields.surname" @keyup.enter="search"/>
        <input type="text" class="form-control" placeholder="Benutzername" v-model="searchFields.username" @keyup.enter="search"/>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary"
                  type="button"
                  @click="search()">
            Suchen
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Benutzer</h4>
      <ul
          class="list-group"
          style="max-height: 80vh; overflow: scroll; -webkit-overflow-scrolling: touch"
      >
        <li class="list-group-item"
            :class="{ active: index === currentIndex }"
            v-for="(user, index) in users"
            :key="index"
            @click="setActiveUser(user, index)"
        >
          {{ user.surname }}, {{ user.forename }}
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <div v-if="currentUser" class="mt-4">
        <h5>
          {{ currentUser.surname }}, {{ currentUser.forename }}
        </h5>
        <p v-if="currentUser.isSuperuser" class="bg-danger text-white p-3 rounded">
          Dieser Benutzer ist Superuser!
        </p>
        <dl class="m-1">
          <dt>Benutzername:</dt>
          <dd>{{ currentUser.username }}</dd>

          <dt>E-Mail:</dt>
          <dd>{{ currentUser.email }}</dd>
        </dl>
        <h6 class="mt-3">Zugriffsrechte</h6>
        <table class="table mt-3">
          <thead>
          <tr>
            <th>Rolle</th>
            <th>Mandant</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="mandant in mandants" :key="mandant._id">
            <td>
              <div class="form-check" v-for="(role, index) in roles" :key="`role-${index}`">
                <input
                    type="checkbox"
                    :id="role._id + mandant._id"
                    class="form-check-input"
                    :checked="currentUser.permissions.find(el => el.mandant._id === mandant._id && el.role._id === role._id)"
                    disabled>
                <label class="form-check-label" :for="role._id + mandant._id">{{ role.name }}</label>
              </div>
            </td>
            <td>{{ mandant.mdts }} {{ mandant.firmenbezeichnung.voll }}</td>
          </tr>
          </tbody>
        </table>

        <router-link :to="'/users/' + currentUser._id" class="btn btn-primary text-white mb-3">Bearbeiten</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import MandantService from "../services/mandant.service";

export default {
  name: "users-list",
  data() {
    return {
      users: [],
      currentUser: null,
      roles: [],
      mandants: [],
      currentIndex: -1,
      searchFields: {
        forename: "",
        surname: "",
        username: ""
      }
    };
  },
  methods: {
    init() {
      this.getRoles()
      this.getUsers()
      this.getMandants()
    },

    getUsers() {
      UserService.getAll()
          .then(response => {
            this.users = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    getRoles() {
      UserService.getAllRoles()
          .then(response => {
            this.roles = response.data
          })
          .catch(reason => {
            this.error = reason.message
          })
    },

    getMandants() {
      MandantService.getAll()
          .then(response => {
            this.mandants = response.data;
          })
          .catch(reason => {
            this.error = reason.message
          })
    },

    refreshList() {
      this.getUsers();
      this.currentUser = null;
      this.currentIndex = -1;
    },

    setActiveUser(user, index) {
      this.currentUser = user;
      this.currentIndex = user ? index : -1;
    },

    search() {
      UserService.search(this.searchFields.forename, this.searchFields.surname, this.searchFields.username)
          .then(response => {
            this.setActiveUser(null);
            this.users = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },
  },

  mounted() {
    this.init()
  }
};
</script>

<style scoped lang="scss">
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 1;
}
</style>